.header{
    margin-bottom: 15px;
    color: #1F363D;
    font-size: 32px;
    font-family: 'Gotham Bold', serif;
}
@media(max-width: 600px){
    .header{
        font-size: 24px;
    }
}
.description{
    color: #1F363D;
}
.comments{
    margin-top: 25px;
}
@media(max-width: 600px){
    .comments{
        margin-top: 15px;
    }
}
.comment{
    margin-bottom: 15px;
}