.container{
    display: inline-block;
}
.label{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.asterisk{
    margin-left: 5px;
    color: #EB443F;
}
.inputContainer{
    display: flex;
    position: relative;
}
.input{
    height: 16px;
    width: calc(25% - 42px);
    padding: 20px 15px;
    color: #1F363D;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    border-top: none;
    border-bottom: 1px solid #40798C66;
    border-left: none;
    border-right: none;
    border-radius: 0;
}
.input:not(:last-of-type){
    margin-right: 20px;
}
.input::placeholder{
    color: #1F363D80;
}
.input:focus{
    border-bottom: 1px solid #40798C;
    outline: none;
}
.error{
    border: 1px solid #EB443F;
}
.disabled{
    color: #1F363D80;
}