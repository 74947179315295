.container{
    position: relative;
}
.button{
    display: flex;
    align-items: center;
    position: relative;
    height: 24px;
    padding: 15px 26px;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
    cursor: pointer;
}
.icon{
    position: relative;
    top: 3px;
    height: 24px;
    width: 24px;
    margin-right: 15px;
}
.label{
    position: relative;
    top: -2.5px;
    color: #274C77;
    font-family: 'Gotham Medium', serif;
}
.downCaret, .upCaret{
    position: relative;
    top: 1.5px;
    height: 7px;
    width: 12px;
    margin-left: auto;
}
.options{
    position: absolute;
    right: 0;
    width: calc(100% - 52px);
    padding: 3px 26px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 10px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.option{
    padding-top: 15px;
    padding-bottom: 15px;
    color: #1F363D;
    font-family: 'Gotham Medium', serif;
    cursor: pointer;
}
.option:not(:last-child){
    border-bottom: 1px solid #1F363D1A;
}
.selected{
    color: #274C77;
}
.menuLinkIcon{
    position: relative;
    top: -1px;
    height: 24px;
    width: 24px;
    margin-right: 15px;
}
.menuLinkLabel{
    position: relative;
    top: -6px;
}