.container{
    display: inline-block;
}
.label{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.asterisk{
    margin-left: 5px;
    color: #EB443F;
}
.inputContainer{
    position: relative;
}
.input{
    height: 16px;
    width: 100%;
    padding: 28px 24px 28px 55px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
}
.input::placeholder{
    color: #1F363D80;
}
.input:focus{
    border: 1px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.input :global(.react-date-picker__wrapper){
    position: relative;
    top: -14px;
    margin-left: -7px;
    border: none;
}
.input :global(.react-calendar__navigation){
    margin-bottom: 5px;
}
.input :global(.react-date-picker__inputGroup){
    height: 30px;
}
.input :global(.react-date-picker__inputGroup__input::placeholder){
    color: #1F363D80;
    font-size: 14px;
}
.input :global(.react-date-picker__inputGroup__input:focus-visible){
    outline: none;
}
.input :global(.react-date-picker__inputGroup__input:invalid){
    background-color: #FFFFFF;
}
.input :global(.react-date-picker__inputGroup__divider){
    color: #1F363D80;
    font-size: 14px;
}
.input :global(.react-calendar){
    padding: 5px;
    color: #1F363D;
    font-family: 'Gotham', serif;
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.input :global(.react-calendar__navigation > button){
    color: #1F363D;
    font-family: 'Gotham Medium', serif;
}
.input :global(.react-calendar__navigation > button:hover), .input :global(.react-calendar__navigation > button:focus), .input :global(.react-calendar__navigation > button:active),
.input :global(.react-calendar__navigation > button:disabled){
    background-color: #FFFFFF;
}
.input :global(.react-calendar__month-view__weekdays__weekday){
    font-family: 'Gotham Medium', serif;
}
.input :global(.react-calendar__month-view__weekdays__weekday > abbr){
    text-decoration: none;
}
.input :global(.react-calendar__year-view__months__month:hover), .input :global(.react-calendar__year-view__months__month:focus), .input :global(.react-calendar__year-view__months__month:active){
    background-color: #FFFFFF;
}
.input :global(.react-calendar__year-view__months__month.react-calendar__tile--hasActive){
    background-color: #FFFFFF;
}
.input :global(.react-calendar__year-view__months__month:disabled){
    color: #D3D3D3;
    background-color: #FFFFFF;
}
.input :global(.react-calendar__decade-view__years__year:hover), .input :global(.react-calendar__decade-view__years__year:focus), .input :global(.react-calendar__decade-view__years__year:active){
    background-color: #FFFFFF;
}
.input :global(.react-calendar__decade-view__years__year.react-calendar__tile--hasActive){
    background-color: #FFFFFF;
}
.input :global(.react-calendar__decade-view__years__year:disabled){
    color: #D3D3D3;
    background-color: #FFFFFF;
}
.input :global(.react-calendar__century-view__decades__decade:hover), .input :global(.react-calendar__century-view__decades__decade:focus),
.input :global(.react-calendar__century-view__decades__decade:active){
    background-color: #FFFFFF;
}
.input :global(.react-calendar__century-view__decades__decade.react-calendar__tile--hasActive){
    background-color: #FFFFFF;
}
.input :global(.react-calendar__century-view__decades__decade:disabled){
    color: #D3D3D3;
    background-color: #FFFFFF;
}
.input :global(.react-calendar__tile){
    height: 48px;
    width: 48px;
    color: #1F363D;
    border-radius: 100px;
}
.input :global(.react-calendar__tile--now){
    background-color: #FFFFFF;
}
.input :global(.react-calendar__tile--now.react-calendar__month-view__days__day:hover){
    background-color: #E6E6E6;
}
.input :global(.react-calendar__tile--now:focus), .input :global(.react-calendar__tile--now:active){
    background-color: #FFFFFF;
}
.input :global(.react-calendar__tile--active){
    color: #FFFFFF;
    background-color: #40798C;
    font-family: 'Gotham Medium', serif;
}
.input :global(.react-calendar__tile--active:hover){
    background-color: #40798C;
}
.input :global(.react-calendar__month-view__days__day:disabled){
    color: #D3D3D3;
    background-color: #FFFFFF;
}
.input :global(.react-calendar__month-view__days__day--neighboringMonth){
    color: #8F9B9E;
}
.calendar{
    position: absolute;
    top: 18px;
    left: 20px;
    height: 24px;
    width: 24px;
}