.error{
    display: block;
    margin-top: 5px;
    text-align: center;
    color: #EB443F;
    font-size: 14px;
    scroll-margin: 100px;
}
.warning{
    position: relative;
    top: 2px;
    height: 16px;
    width: 16px;
    padding-right: 5px;
}