.container{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    border-radius: 30px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.topSection{
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 1224px;
}
.header{
    text-align: left;
}
.organizationContainer{
    display: flex;
    margin-left: auto;
}
@media(max-width: 1370px){
    .topSection{
        flex-direction: column;
        width: 725px;
        margin-bottom: 20px;
    }
    .header{
        margin-bottom: 18px;
        text-align: center;
    }
    .organizationContainer{
        margin-left: 0;
    }
}
@media(max-width: 862px){
    .topSection{
        width: calc(100% - 70px);
    }
}
@media(max-width: 600px){
    .topSection{
        width: calc(100% - 40px);
    }
}
.organization{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 22px;
    margin-left: auto;
    padding: 10px 20px;
    color: #40798C;
    background-color: #70A9A11A;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
    border-radius: 25px;
}
@media(max-width: 862px){
    .organization{
        text-align: center;
        line-height: 16px;
    }
}
.middleSection{
    display: flex;
    justify-content: center;
}
.leftSide{
    width: 500px;
}
@media(max-width: 1370px){
    .middleSection{
        align-items: center;
        flex-direction: column;
    }
    .leftSide{
        width: 725px;
    }
}
@media(max-width: 862px){
    .leftSide{
        width: calc(100% - 70px);
    }
}
@media(max-width: 600px){
    .leftSide{
        width: calc(100% - 40px);
    }
}
.singleLineInput, .singleLineCheckbox, .singleLineDropdown, .singleLineMoneyInput{
    display: block !important;
    margin-top: 15px;
}
.singleLineInput input{
    width: calc(100% - 44px);
}
.singleLineCheckbox{
    margin-left: 6px;
}
.singleLineDropdown select{
    width: 100%;
}
.singleLineMoneyInput input{
    width: calc(100% - 80px);
}
.singleLineTextEdtior{
    display: block !important;
    height: calc(100% - 34px);
    margin-top: 20px;
}
@media(max-width: 1370px){
    .singleLineTextEdtior{
        margin-top: 15px;
    }
}
.singleLineTextEdtior textarea{
    width: calc(100% - 48px);
}
.startTimeRow{
    display: flex;
    margin-top: 15px;
}
.startTime select{
    width: 242px;
}
@media(max-width: 1370px){
    .startTime select{
        width: 354px;
    }
}
@media(max-width: 862px){
    .startTime{
        width: calc(50% - 10px);
    }
    .startTime select{
        width: 100%;
    }
}
@media(max-width: 680px){
    .startTimeRow{
        flex-direction: column;
    }
    .startTime{
        width: 100%;
    }
    .startTime:first-child{
        margin-bottom: 15px;
    }
}
.rightSide{
    width: 694px;
    margin-left: 30px;
}
@media(max-width: 1370px){
    .rightSide{
        height: 378px;
        width: 725px;
        margin-left: 0;
        padding-top: 0;
    }
}
@media(max-width: 862px){
    .rightSide{
        width: calc(100% - 70px);
    }
}
@media(max-width: 600px){
    .rightSide{
        width: calc(100% - 40px);
    }
}
.bottomSection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 45px;
    margin-bottom: 20px;
}
@media(max-width: 1370px){
    .bottomSection{
        margin-top: 35px;
        margin-bottom: 15px;
    }
}
.apiError{
    margin-top: -15px;
    margin-bottom: 20px;
}
.button{
    width: 200px;
    padding-left: 100px;
    padding-right: 100px;
}