.topRow{
    display: flex;
}
.header{
    margin-bottom: 15px;
    color: #1F363D;
    font-size: 32px;
    font-family: 'Gotham Bold', serif;
}
.sortOrder{
    margin-top: 20px;
    margin-left: auto;
}
@media(max-width: 600px){
    .header{
        font-size: 24px;
    }
    .sortOrder{
        margin-top: 10px;
    }
}
.button{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.updatesContainer{
    margin-top: 15px;
    margin-left: 5px;
}
@media(max-width: 600px){
    .updatesContainer{
        margin-top: 15px;
    }
}
.updates{
    margin-top: 10px;
    padding-left: 25px;
    border-left: 2px solid #ECECEC;
}
.update{
    margin-bottom: 15px;
}
.noUpdates{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    width: 300px;
    text-align: center;
}
@media(max-width: 600px){
    .noUpdates{
        margin-top: 20px;
    }
}
.bellQuestionMark{
    height: 45px;
    width: 45px;
}
.noUpdatesText{
    margin-top: 15px;
    margin-bottom: 15px;
    color: #1F363D;
    font-size: 20px;
    font-family: 'Gotham Bold', serif;
}