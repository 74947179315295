.button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 45px;
    color: #FFFFFF;
    background-color: #40798C;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
    border: none;
    border-radius: 500px;
    cursor: pointer;
}
.button:focus-visible{
    outline: none;
}
.disabled{
    background-color: #CFE2E8 !important;
}