.uploadImageInput{
    display: none;
}
.imageSection{
    position: relative;
    height: 160px;
    width: 160px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
    cursor: pointer;
}
.image{
    height: 160px;
    width: 160px;
    border-radius: 50%;
    object-fit: cover;
}
.imagePlaceholder{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #578E86;
    background-color: #BFE1DC;
    font-size: 44px;
    font-family: 'Gotham Medium', serif;
    border-radius: 50%;
    letter-spacing: -3px;
}
.imagePlaceholderIcon{
    height: 33px;
    width: 33px;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%);
}
.camera{
    position: absolute;
    height: 18px;
    width: 20px;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}