.container{
    padding-top: 10px;
    padding-bottom: 10px;
}
.topRow{
    display: flex;
    align-items: center;
    position: relative;
}
@media(max-width: 600px){
    .topRow{
        display: block;
        height: auto;
        margin-bottom: 0;
    }
}
.borderCover{
    position: absolute;
    left: -30px;
    bottom: calc(100% - 20px);
    height: 100%;
    width: 5px;
    background-color: #FFFFFF;
}
.outerCircle{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    left: -35px;
    height: 18px;
    width: 18px;
    background-color: #40798C;
    border-radius: 50%;
}
.innerCircle{
    height: 8px;
    width: 8px;
    background-color: #FFFFFF;
    border-radius: 50%;
}
.title{
    margin-right: 25px;
    margin-bottom: 2px;
    color: #40798C;
    font-size: 20px;
    font-family: 'Gotham Bold', serif;
}
.organizer{
    display: flex;
    align-items: center;
    margin-left: auto;
}
.organizerPicture{
    height: 41px;
    width: 41px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
}
.organizerName{
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
    white-space: nowrap;
}
.middleRow{
    display: flex;
    height: 41px;
    margin-bottom: -7px;
}
.datetime{
    color: #1F363D;
    font-size: 14px;
    opacity: 60%;
}
@media(max-width: 600px){
    .organizer{
        top: 0;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .organizerPicture{
        height: 32px;
        width: 32px;
    }
    .organizerName{
        font-size: 14px;
    }
}
.text{
    color: #1F363D;
    font-size: 14px;
    white-space: pre-wrap;
    overflow-x: auto;
}
.pictures{
    margin-top: 10px;
}
.picture{
    width: calc(33.33% - 10px);
    border-radius: 10px;
    cursor: pointer;
}
.picture:not(:last-child){
    margin-right: 15px;
}