.container{
    display: inline-block;
}
.label{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.asterisk{
    margin-left: 5px;
    color: #EB443F;
}
.dropdownContainer{
    position: relative;
}
.select{
    padding: 20px 24px 20px 50px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 5px top;
}
.noImage{
    padding-left: 24px;
}
.select:focus{
    border: 1px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.error{
    border: 1px solid #EB443F;
}
.select option{
    color: #1F363D;
    font-size: 14px;
    font-family: 'Gotham', serif;
}
.image{
    position: absolute;
    top: 15px;
    left: 20px;
    height: 24px;
    width: 24px;
}
.downCaret{
    position: absolute;
    top: 25px;
    right: 24px;
    height: 7.5px;
    width: 12px;
}