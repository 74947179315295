.container{
    display: flex;
    min-height: calc(100vh - 210px);
    width: 866px;
    margin: 20px auto;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 30px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
@media(max-width: 930px){
    .container{
        width: 100%;
    }
}
@media(max-width: 700px){
    .container{
        min-height: calc(100vh - 190px);
    }
}
.menu{
    padding: 37px 0;
    border-right: 1px solid #1F363D1A;
}
@media(max-width: 700px){
    .menu{
        display: none;
    }
}
.menuLink{
    display: flex;
    width: 240px;
    margin-bottom: 32px;
    padding: 2px 25px;
    color: #1F363D;
    background-color: #FFFFFF;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
    border: none;
    cursor: pointer;
}
.menuLink:focus-visible{
    outline: none;
}
.selected{
    padding-left: 20px;
    color: #274C77;
    border-left: 5px solid #274C77;
}
.menuLinkIcon{
    position: relative;
    top: -1px;
    height: 20px;
    width: 20px;
    margin-right: 15px;
}
.views{
    width: calc(100% - 80px);
    padding: 35px 40px;
    overflow-x: hidden;
}
@media(max-width: 700px){
    .views{
        width: calc(100% - 40px);
        padding: 35px 20px;
    }
}
.menuDropdown{
    display: none;
    margin-bottom: 25px;
    z-index: 200;
}
@media(max-width: 700px){
    .menuDropdown{
        display: block;
    }
}
.personalInfo, .organizationInfo, .security{
    z-index: 100;
}
@media(max-width: 700px){
    .security{
        margin-top: 60px;
    }
}