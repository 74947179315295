.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 400;
}
.container{
    position: relative;
    height: 90%;
    max-height: 600px;
    width: 85%;
    max-width: 412px;
    padding: 20px 20px 0 20px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.header{
    margin-bottom: 8px;
    color: #1F363D;
    font-size: 31px;
    font-family: 'Gotham Bold', serif;
}
.close{
    position: absolute;
    top: 28px;
    right: 33px;
    height: 12px;
    width: 12px;
    cursor: pointer;
}
.nav{
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px;
    border-bottom: 1px solid #E9EBEC;
}
.view{
    padding-bottom: 15px;
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
    cursor: pointer;
}
.view:nth-of-type(1){
    margin-right: 35px;
}
.selected{
    color: #274C77;
    border-bottom: 5px solid #274C77;
}
.donations{
    max-height: calc(100% - 200px);
    overflow-y: auto;
}
.button{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    padding-left: 50px;
    padding-right: 50px;
}