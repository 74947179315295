.container{
    width: calc(100% - 50px);
    padding: 24px 25px 24px 25px;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.totalRaisedAmount{
    margin-right: 5px;
    color: #1F363D;
    font-size: 31px;
    font-family: 'Gotham Bold', serif;
}
.totalRaisedtext{
    color: #40798C;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
}
.progress{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.progressBar{
    height: 8px;
    width: 90%;
    margin-top: 8px;
    background-color: #ECECEC;
    border-radius: 500px;
}
@media(max-width: 1080px){
    .progressBar{
        width: 100%;
    }
}
.progressMade{
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #BC4749 0%, #FFEC41 49%, #A7C957 100%);
    border-radius: 500px;
}
.totalDonations{
    display: block;
    margin-top: 7px;
    color: #1F363D80;
    text-align: center;
    font-size: 14px;
}
.donateButton{
    width: 100%;
    margin-top: 20px;
}
.shareButton{
    width: 100%;
    margin-top: 15px;
}
@media(max-width: 1080px){
    .donateShareButtons{
        display: flex;
    }
    .donateButton, .shareButton{
        width: calc(50% - 10px);
        margin-top: 25px;
    }
    .donateButton{
        margin-right: 20px;
    }
}
@media(max-width: 600px){
    .donateShareButtons{
        display: block;
    }
    .donateButton{
        width: 100%;
        margin-top: 20px;
        margin-right: 0;
    }
    .shareButton{
        width: 100%;
        margin-top: 15px;
    }
}
.receiptLink{
    display: block;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 8px;
    color: #40798C;
    text-align: center;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
}
.receipt{
    position: relative;
    top: 5px;
    height: 20px;
    width: 18px;
    margin-right: 5px;
}
.donations{
    margin-top: 10px;
}
.donationsButtons{
    display: flex;
    margin-top: 10px;
}
.seeAllButton, .topDonorsButton{
    width: calc(50% - 5px);
}
.seeAllButton{
    margin-right: 10px;
}
@media(max-width: 1080px){
    .seeAllButton, .topDonorsButton{
        width: calc(50% - 10px);
    }
    .seeAllButton{
        margin-right: 20px;
    }
}