.container{
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    padding-top: 30px;
}
.banner{
    height: calc(100vh - 154px);
    width: 40%;
    padding-bottom: 50px;
}
@media(max-width: 1180px){
    .container{
        display: block;
        height: auto;
    }
    .banner{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        margin-top: -25px;
    }
}
@media(max-width: 600px){
    .container{
        padding-top: 0;
    }
}
.bottomBar{
    display: flex;
    align-items: center;
    margin-top: -5px;
    padding: 20px 25px 20px 30px;
    background-color: #40798C;
    border-radius: 0 0 30px 30px;
}
@media(max-width: 1180px){
    .bottomBar{
        margin-top: -5px;
        padding-bottom: 50px;
        border-radius: 30px 30px 0 0;
    }
}
@media(max-width: 480px){
    .bottomBar{
        flex-direction: column;
        padding: 25px 25px 60px 25px;
    }
}
.bottomBarText{
    margin-right: 25px;
    font-size: 31px;
    color: #FFFFFF;
    line-height: 31px;
}
.image{
    height: calc(100% - 88px);
    width: 100%;
    border-radius: 30px 30px 0 0;
    object-fit: cover;
}
@media(max-width: 1180px){
    .image{
        margin-top: -30px;
        object-position: top;
    }
}
.loginButton{
    min-width: 108px;
    margin-left: auto;
}
@media(max-width: 480px){
    .loginButton{
        width: calc(100% - 90px);
        margin-top: 20px;
    }
}
.formContainer{
    display: flex;
    justify-content: center;
    height: calc(100vh - 174px);
    width: 60%;
    padding-top: 70px;
    overflow-y: auto;
    overflow-x: hidden;
}
@media(max-width: 1180px){
    .formContainer{
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        height: 500px;
        width: 500px;
        padding-top: 0;
    }
}
@media(max-width: 600px){
    .formContainer{
        height: auto;
        width: calc(100% - 30px);
        margin: 30px 15px 120px 15px;
    }
}
.form{
    width: 500px;
}
.formNamesRow{
    display: flex;
}
.header{
    text-align: left !important;
    word-break: normal;
}
.description{
    margin-top: -12px;
    margin-bottom: 45px;
    padding-left: 2px;
}
.name input{
    width: 190px;
}
.singleLineInput{
    display: block !important;
    margin-top: 15px;
}
.singleLineInput input{
    width: calc(100% - 40px);
}
@media(max-width: 600px){
    .form{
        width: 100%;
    }
    .formNamesRow{
        display: block;
    }
    .name{
        display: block !important;
        margin-top: 15px;
    }
    .name input{
        width: calc(100% - 45px);
    }
    .singleLineInput input{
        width: calc(100% - 45px);
    }
}
.formBottom{
    display: flex;
    align-items: center;
    margin-top: 50px;
}
.shieldCheckmark{
    height: 60px;
    width: 51px;
}
.resetSuccessfulHeader{
    margin-top: 35px;
    margin-bottom: 25px;
    font-size: 31px;
    font-family: "Gotham Bold", serif;
}