.button{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 25px;
    color: #396D51;;
    background-color: #9DD1A8;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
    border: none;
    border-radius: 500px;
    outline: none;
    cursor: default;
}
.checkmark{
    margin-left: 8px;
    height: 20px;
    width: 20px;
}