.container{
    position: relative;
    z-index: 50;
}
.button{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 24px;
    padding: 12px 16px;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
    cursor: pointer;
}
.upDown{
    height: 24px;
    width: 24px;
    margin-right: 8px;
}
.downCaret, .upCaret{
    height: 7px;
    width: 12px;
    margin-left: 10px;
}
.options{
    position: absolute;
    right: 0;
    width: 139px;
    padding: 3px 18px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 10px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.option{
    padding-top: 15px;
    padding-bottom: 15px;
    color: #1F363D;
    font-size: 14px;
    cursor: pointer;
}
.option:not(:last-child){
    border-bottom: 1px solid #1F363D1A;
}
.selected{
    color: #40798C;
    font-family: 'Gotham Medium', serif;
}