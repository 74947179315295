.container{
    display: flex;
    min-height: calc(100vh - 280px);
    width: 566px;
    margin: 20px auto;
    padding: 5px 40px 65px 40px;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 30px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
@media(max-width: 730px){
    .container{
        width: calc(100% - 80px);
    }
}
@media(max-width: 600px){
    .container{
        min-height: calc(100vh - 260px);
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
}
.header{
    text-align: left;
    word-break: normal;
}
.email{
    display: block !important;
    margin-top: 40px;
}
.email input{
    width: calc(100% - 44px);
}
.lastFour{
    margin-top: 25px;
}
.bottomSection{
    margin-top: 55px;
}
.apiError{
    margin-top: -15px;
    margin-bottom: 20px;
}
.button{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 261px;
}
.bubbleCheckmark{
    display: block;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 60px;
    width: 60px;
    margin-top: 30%;
}
@media(max-width: 900px){
    .bubbleCheckmark{
        margin-top: 30px;
    }
}
.requestedText{
    margin-top: 40px;
    text-align: center;
    font-family: 'Gotham Bold', serif;
    font-size: 31px;
    line-height: 37px;
}