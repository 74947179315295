.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 400;
}
.container{
    position: relative;
    max-height: 600px;
    width: 85%;
    max-width: 400px;
    padding: 20px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.header{
    margin-bottom: 8px;
    color: #1F363D;
    font-size: 31px;
    font-family: 'Gotham Bold', serif;
}
.close{
    position: absolute;
    top: 28px;
    right: 22px;
    height: 12px;
    width: 12px;
    cursor: pointer;
}
.cropperContainer{
    position: relative;
    height: 400px;
    width: 400px;
    margin-top: 30px;
    margin-bottom: 10px;
}
@media(max-width: 500px){
    .cropperContainer{
        height: auto;
        width: 100%;
        aspect-ratio: 1 / 1;
    }
}
.cropperContainer :global(.reactEasyCrop_Container){
    border-radius: 28px;
}
.slider{
    width: 100%;
    margin-top: 30px;
}
.sliderTrack{
    top: 0;
    bottom: 0;
    height: 8px;
    background-color: #40798C;
    border-radius: 999px;
}
.sliderThumbContainer:focus-visible{
    outline: none !important;
}
.sliderThumb{
    position: relative;
    top: -12.5px;
    height: 16px;
    width: 16px;
    background-color: #FFFFFF;
    border: 1px solid #40798C66;
    border-radius: 50%;
    cursor: pointer;
}
.buttons{
    display: flex;
    margin-top: 35px;
}
.button{
    width: 194px;
    padding-left: 50px;
    padding-right: 50px;
}
@media(max-width: 500px){
    .button{
        width: calc(50% - 6px);
    }
}