.buttonLink{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 45px;
    color: #40798C;
    background-color: #FFFFFF;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
    border-radius: 500px;
    cursor: pointer;
}
.dark{
    color: #FFFFFF;
    background-color: #40798C;
}