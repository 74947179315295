.organizationUrl{
    margin-bottom: 25px;
}
.dualInputsRow, .bankInfoRow{
    display: flex;
    margin-bottom: 15px;
}
.halfLineInput input{
    width: 215px;
}
.halfLineInput:last-child{
    margin-left: auto;
}
.halfLineInput select{
    width: 264px;
}
@media(max-width: 930px){
    .dualInputsRow{
        display: block;
        margin-bottom: 0;
    }
    .halfLineInput{
        display: block;
        margin-bottom: 15px;
    }
    .halfLineInput input{
        width: calc(100% - 50px);
    }
    .halfLineInput{
        width: 100%;
    }
    .halfLineInput select{
        width: 100%;
    }
}
.singleLineInput{
    display: block !important;
    margin-bottom: 15px;
}
.singleLineInput input{
    width: calc(100% - 50px);
}
.bankInfoRow{
    margin-top: 25px;
    align-items: center;
}
.bankInfoLabel{
    position: relative;
    top: -2px;
    margin-left: 2px;
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
}
.bankIcon{
    position: relative;
    top: 3px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}
.exclamation{
    position: relative;
    top: 1px;
    height: 16px;
    width: 18px;
    margin-left: 6px;
}
.checkmark{
    position: relative;
    top: 2.5px;
    height: 16px;
    width: 16px;
    margin-left: 7px;
}
.bankInfoButton{
    height: 38px;
    margin-left: auto;
    padding: 0 30px;
    font-size: 14px;
    border: 1px solid #40798C66;
}
@media(max-width: 400px){
    .bankIcon{
        margin-right: 5px;
    }
    .exclamation, .checkmark{
        margin-left: 3px;
    }
    .bankInfoButton{
        padding: 0 15px;
    }
}
.button{
    width: 261px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
}