.container{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.errorCode{
    color: #1F363D;
    font-size: 181px;
    font-family: "Gotham Bold", serif;
}
@media(max-width: 520px){
    .errorCode{
        font-size: 120px;
    }
}
.text{
    margin-top: 20px;
    margin-bottom: 40px;
    color: #1F363D;
    font-size: 16px;
}
.image{
    height: calc(100vh - 478px);
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
    object-fit: cover;
}
@media(max-width: 520px){
    .image{
        height: calc(100vh - 405px);
    }
}