.imagesSection{
    margin-top: 15px;
}
.uploadImageInput{
    display: none;
}
.uploadImage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    border: 1px dashed #1F363D33;
    border-radius: 28px;
    cursor: pointer;
}
.download{
    height: 20px;
    width: 16px;
}
.uploadHeader{
    margin-top: 10px;
    color: #40798C;
    font-family: 'Gotham Medium', serif;
}
.uploadDescription{
    margin-top: 3px;
    color: #1F363D;
    font-size: 12px;
    opacity: 60%;
}
.images{
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}
.imageContainer{
    position: relative;
    height: 89px;
    margin-bottom: 8px;
}
.imageContainer:not(:nth-child(3n)){
    margin-right: 12px;
}
@media(max-width: 1370px){
    .images{
        justify-content: center;
    }
    .imageContainer{
        margin-bottom: 5px;
    }
    .imageContainer:nth-child(3n){
        margin-right: 12px;
    }
}
.image{
    height: 89px;
    width: 158px;
    border-radius: 6px;
    object-fit: cover;
}
.sortOrder{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 30px;
    left: 5px;
    height: 22px;
    width: 22px;
    color: #FFFFFF;
    background-color: #40798C;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
    border-radius: 50%;
}
.leftArrowContainer, .rightArrowContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 36px;
    height: 15px;
    width: 15px;
    color: #1C1B1F;
    background-color: #FFFFFF;
    border-radius: 50%;
    font-size: 30px;
    cursor: pointer;
}
.leftArrowContainer{
    left: 5px;
}
.rightArrowContainer{
    right: 5px;
}
.leftArrow, .rightArrow{
    height: 5px;
    width: 6px;
}
.removeImage{
    position: absolute;
    top: 2px;
    right: 2px;
    height: 24px;
    width: 24px;
    cursor: pointer;
}