.container{
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    padding-top: 30px;
}
.banner{
    height: auto;
    width: 40%;
    padding-bottom: 50px;
}
@media(max-width: 1180px){
    .container{
        display: block;
        height: auto;
    }
    .banner{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        margin-top: -25px;
    }
}
@media(max-width: 600px){
    .container{
        min-height: calc(100vh - 104px);
        padding-top: 0;
    }
}
.image{
    height: 100%;
    max-height: 100%;
    width: 100%;
    border-radius: 30px 30px 30px 30px;
    object-fit: cover;
}
@media(max-width: 1180px){
    .image{
        margin-top: -30px;
        object-position: top;
    }
}
.formContainer{
    display: flex;
    justify-content: center;
    height: calc(100vh - 174px);
    width: 60%;
    padding-top: 70px;
}
@media(max-width: 1180px){
    .formContainer{
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        height: 600px;
        width: 500px;
        padding-top: 0;
    }
}
@media(max-width: 600px){
    .formContainer{
        height: auto;
        width: calc(100% - 30px);
        margin: 30px 15px 120px 15px;
    }
}
.form{
    width: 500px;
}
.formNamesRow{
    display: flex;
}
.header{
    text-align: left !important;
}
.description{
    margin-top: -12px;
    margin-bottom: 45px;
    padding-left: 2px;
}
.name input{
    width: 190px;
}
.singleLineInput{
    display: block !important;
    margin-top: 15px;
}
.singleLineInput input{
    width: calc(100% - 40px);
}
@media(max-width: 600px){
    .form{
        width: 100%;
    }
    .formNamesRow{
        display: block;
    }
    .name{
        display: block !important;
        margin-top: 15px;
    }
    .name input{
        width: calc(100% - 45px);
    }
    .singleLineInput input{
        width: calc(100% - 45px);
    }
}
.formBottom{
    display: flex;
    align-items: center;
    margin-top: 50px;
}
.invalidTokenDescription{
    text-align: center;
}
.shieldCheckmark{
    height: 60px;
    width: 60px;
}
.resetSuccessfulHeader{
    margin-top: 35px;
    margin-bottom: 25px;
    font-size: 31px;
    font-family: "Gotham Bold", serif;
}