.container{
    display: inline-block;
}
.inputContainer{
    position: relative;
}
.input{
    height: 16px;
    padding: 20px 24px 20px 50px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
}
.input::placeholder{
    color: #1F363D80;
}
.input:focus{
    border: 6px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.error{
    border: 1px solid #EB443F;
}
.dollarCircleOrange{
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
}