.container{
    display: flex;
    min-height: calc(100vh - 230px);
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    background-color: #FFFFFF;
    border-radius: 30px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.fundraisers{
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
}
@media(max-width: 1320px){
    .fundraisers{
        width: calc(100% - 70px);
    }
}
@media(max-width: 600px){
    .fundraisers{
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
}
.topSection{
    display: flex;
    align-items: baseline;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
@media(max-width: 1000px){
    .topSection{
        flex-wrap: wrap;
    }
}
.fundraisersHeader{
    text-align: left;
}
.bottomSection{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}
@media(max-width: 1400px){
    .bottomSection{
        justify-content: center;
    }
}
.fundraiser{
    margin-bottom: 35px;
}
.fundraiser:not(:last-of-type){
    margin-right: 30px;
}
@media(max-width: 1400px){
    .fundraiser{
        width: calc(50% - 66px) !important;
    }
    .fundraiser:nth-of-type(even){
        margin-right: 0;
    }
}
@media(max-width: 1320px){
    .fundraiser{
        width: calc(33% - 66px) !important;
    }
    .fundraiser:nth-of-type(even){
        margin-right: 30px;
    }
    .fundraiser:nth-of-type(3n){
        margin-right: 0;
    }
}
@media(max-width: 1200px){
    .fundraiser{
        width: calc(50% - 66px) !important;
    }
    .fundraiser:nth-of-type(even){
        margin-right: 0;
    }
    .fundraiser:nth-of-type(3n){
        margin-right: auto;
    }
}
@media(max-width: 820px){
    .fundraiser{
        width: calc(100% - 48px) !important;
        margin-bottom: 25px;
        margin-right: 0 !important;
    }
}
.rightSide{
    display: flex;
    position: relative;
    top: -8px;
    margin-left: auto;
}
@media(max-width: 600px){
    .rightSide{
        margin-top: 10px;
    }
}
.searchInput{
    margin-right: 20px;
}
@media(max-width: 1000px){
    .rightSide{
        width: 100%;
    }
    .searchInput{
        width: calc(100% - 335px);
    }
    .searchInput > div{
        width: 100%;
    }
    .searchInput input{
        width: calc(100% - 80px);
    }
}
.searchFilter{
    margin-left: auto;
    margin-right: 15px;
}
.searchOrder{
    margin-left: auto;
}
@media(max-width: 560px){
    .rightSide{
        flex-wrap: wrap;
    }
    .searchInput{
        display: block;
        width: 100%;
        margin-right: 0;
    }
    .searchFilter, .searchOrder{
        width: calc(50% - 5px);
        margin-top: 12px;
        margin-left: 0;
    }
    .searchFilter{
        margin-right: 10px;
    }
}
.noFundraisers{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
}
.magnifyingGlassWithQuestionMark{
    height: 60px;
    width: 60px;
}
.noFundraisersDescription{
    margin-top: 35px;
    color: #1F363D;
    text-align: center;
    font-size: 31px;
    font-family: 'Gotham Bold', serif;
}
.noFundraisersButton{
    width: 150px;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 4px 20px;
    color: #FFFFFF;
    background-color: #40798C;
    font-size: 14px;
}