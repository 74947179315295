.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 400;
}
.container{
    position: relative;
    width: 85%;
    padding: 20px 25px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.close{
    position: absolute;
    top: 0;
    right: 0;
    height: 12px;
    width: 12px;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
}
@media(max-width: 600px){
    .container{
        padding: 12px 15px;
    }
    .close{
        padding: 10px;
    }
}
.image{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}