.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    height: 72px;
    width: 100%;
    color: #1F363D;
    background-color: #FFFFFF;
    font-size: 14px;
    border-top: 1px solid #70A9A11A;
    border-radius: 30px 30px 0 0;
}