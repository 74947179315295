.container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 257px;
    padding: 24px 24px 32px 24px;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    text-decoration: none;
    box-shadow: 0 3px 12px 0 #00000012;
}
.name{
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 8px;
    text-align: center;
    color: #40798C;
    font-size: 16px;
    font-family: 'Gotham Bold', serif;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.status{
    margin-bottom: 10px;
    padding: 6px 12px;
    font-size: 12px;
    font-family: 'Gotham Medium', serif;
    border: 1px solid;
    border-radius: 14px;
}
.organization{
    display: flex;
    height: 42px;
    width: 100%;
    margin-top: 18px;
}
.organizationLabel{
    margin-right: 5px;
    color: #1F363D80;
    text-align: left;
    font-size: 14px;
}
.organizationName{
    display: inline-block;
    position: relative;
    top: 0.5px;
    max-width: calc(100% - 70px);
    margin-left: auto;
    color: #1F363D;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.organizationPicture{
    position: relative;
    top: -10px;
    height: 40px;
    width: 40px;
    margin-left: 7px;
    border-radius: 500px;
}
.description{
    display: -webkit-box;
    height: 51px;
    color: #1F363D;
    line-height: 16px;
    font-size: 12px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
}
.notStarted{
    color: #40798C;
    background-color: #ECF2F4;
    border-color: #DBE6EA;
}
.inProgress{
    color: #9C7A00;
    background-color: #FFD952;
    border-color: #EECB4E;
}
.ended{
    color: #396D51;
    background-color: #9DD1A8;
    border-color: #9AC2A0;
}
.label{
    display: block;
    color: #1F363D80;
    text-align: center;
    font-size: 14px;
}
.value{
    color: #1F363D;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
}
.progress{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.progressBar{
    height: 8px;
    width: 90%;
    margin-top: 8px;
    background-color: #ECECEC;
    border-radius: 500px;
}
.progressMade{
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #BC4749 0%, #FFEC41 49%, #A7C957 100%);
    border-radius: 500px;
}
.firstInfoRow{
    display: flex;
    width: 100%;
    margin-top: 20px;
}
.endAt{

}
.startAt{
    margin-left: auto;
}
.secondInfoRow{
    margin-top: 20px;
}
.totalDonations{
    text-align: center;
}