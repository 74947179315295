.container{
    padding-top: 10px;
    padding-bottom: 10px;
}
.name{
    margin-bottom: 5px;
    color: #1F363D;
    font-family: 'Gotham Medium', serif;
}
.info{
    display: flex;
    margin-bottom: 10px;
}
.coin{
    position: relative;
    top: 3px;
    height: 12.5px;
    width: 12.5px;
    margin-right: 5px;
}
.amount{
    color: #1F363D;
    margin-right: 10px;
}
.dot{
    position: relative;
    top: 6px;
    height: 6px;
    width: 6px;
    margin-right: 10px;
    background-color: #1F363D;
    border-radius: 50%;
    opacity: 60%;
}
.datetime{
    color: #1F363D;
    font-size: 14px;
    opacity: 60%;
}
.comment{
    color: #1F363D;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
}