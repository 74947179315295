.container{
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    padding-top: 30px;
}
.banner{
    height: calc(100vh - 154px);
    width: 40%;
    padding-bottom: 50px;
}
@media(max-width: 1080px){
    .container{
        display: block;
        height: auto;
    }
    .banner{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        margin-top: -25px;
    }
}
@media(max-width: 600px){
    .container{
        padding-top: 0;
    }
    .banner{
        margin-top: -60px;
    }
}
.bottomBar{
    display: flex;
    align-items: center;
    margin-top: -5px;
    padding: 20px 25px 20px 30px;
    background-color: #40798C;
    border-radius: 0 0 30px 30px;
}
@media(max-width: 1080px){
    .bottomBar{
        margin-top: -5px;
        padding-bottom: 50px;
        border-radius: 30px 30px 0 0;
    }
}
@media(max-width: 450px){
    .bottomBar{
        flex-direction: column;
        padding: 25px 25px 60px 25px;
    }
}
.bottomBarText{
    margin-right: 25px;
    font-size: 31px;
    color: #FFFFFF;
    line-height: 31px;
}
.image{
    height: calc(100% - 88px);
    width: 100%;
    border-radius: 30px 30px 0 0;
    object-fit: cover;
}
@media(max-width: 1080px){
    .image{
        margin-top: -30px;
    }
}
.loginButton{
    margin-left: auto;
}
@media(max-width: 450px){
    .loginButton{
        width: calc(100% - 90px);
        margin-top: 20px;
    }
}
.formContainer{
    display: flex;
    justify-content: center;
    height: calc(100vh - 154px);
    width: 60%;
    padding-top: 15px;
    overflow-y: auto;
}
@media(max-width: 1080px){
    .formContainer{
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        min-height: 750px;
        width: 500px;
        padding-top: 0;
    }
}
@media(max-width: 600px){
    .formContainer{
        width: calc(100% - 30px);
        margin: 0 15px 90px 15px;
        overflow-y: visible;
    }
}
.form{
    width: 500px;
    margin-bottom: 50px;
}
@media(max-width: 600px){
    .form{
        width: 100%;
    }
}
@media(max-width: 600px){
    .header{
        margin-bottom: 30px;
    }
}
@media(max-width: 530px){
    .header{
        text-align: left !important;
    }
}
.formRadioButtonsRow{
    display: flex;
    margin-bottom: 35px;
}
.radioButton{
    width: 222px;
}
.radioButton:first-child{
    margin-right: 22px;
}
@media(max-width: 600px){
    .formRadioButtonsRow{
        display: block;
        margin-bottom: 25px;
    }
    .radioButton{
        width: calc(100% - 30px) !important;
        margin-bottom: 12px;
    }
}
.dualInputsRow{
    display: flex;
}
.halfLineInput{
    margin-top: 15px;
}
.halfLineInput:last-child{
    margin-left: auto;
}
.halfLineInput input{
    width: 190px;
}
.halfLineInput select{
    width: 238px;
}
.singleLineInput{
    display: block !important;
    margin-top: 15px;
}
.singleLineInput input{
    width: calc(100% - 40px);
}
@media(max-width: 600px){
    .dualInputsRow{
        display: block;
    }
    .halfLineInput{
        display: block !important;
    }
    .halfLineInput input{
        width: calc(100% - 45px);
    }
    .halfLineInput select{
        width: calc(100% + 3px);
    }
    .singleLineInput input{
        width: calc(100% - 45px);
    }
}
.button{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}