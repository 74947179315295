.infoSection{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 30px;
    border-bottom: 1px solid #1F363D1A;
}
.hasImages{
    border-bottom: none;
}
@media(max-width: 600px){
    .infoSection{
        padding-bottom: 20px;
    }
}
.leftSide{
    display: flex;
    width: calc(100% - 200px);
}
.organizer, .organization{
    display: flex;
    align-items: center;
}
.organizerPicture, .organizationPicture{
    height: 41px;
    width: 41px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
}
.imagePlaceholder{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    width: 41px;
    margin-right: 10px;
    color: #578E86;
    background-color: #BFE1DC;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
    border-radius: 50%;
    letter-spacing: -3px;
}
.organizerName, .organizationName{
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
}
@media(max-width: 600px){
    .organizerPicture, .organizationPicture{
        height: 31px;
        width: 31px;
    }
    .imagePlaceholder{
        height: 31px;
        width: 31px;
        font-size: 12px;
        letter-spacing: -1.5px;
    }
    .organizerName, .organizationName{
        font-size: 14px;
    }
}
.organizerName{
    margin-right: 10px;
}
.organizationName{
    margin-right: 50px;
}
.viaText{
    margin-right: 10px;
    color: #1F363D;
    font-size: 16px;
    opacity: 60%;
}
.organizationLocation{
    display: flex;
    align-items: center;
    color: #1F363D;
    font-size: 14px;
}
.locationPin{
    height: 20px;
    width: 14px;
    margin-right: 10px;
}
.startEndText{
    margin-left: auto;
    padding-left: 50px;
    color: #1F363D;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
}
@media(min-width: 1081px) and (max-width: 1280px), (max-width: 800px){
    .leftSide{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .organizationName{
        margin-right: 30px;
    }
    .organizationLocation{
        margin-left: auto;
    }
    .startEndText{
        width: 100%;
        margin-top: 20px;
        margin-left: 0;
        padding-left: 0;
    }
}