.container{
    display: flex;
    min-height: calc(100vh - 230px);
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    background-color: #FFFFFF;
    border-radius: 30px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.fundraisers{
    width: calc(100% - 523px);
    padding-left: 35px;
    padding-right: 35px;
}
@media(max-width: 1320px){
    .container{
        flex-direction: column-reverse;
    }
    .fundraisers{
        width: calc(100% - 70px);
    }
}
@media(max-width: 600px){
    .fundraisers{
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
}
.topSection{
    display: flex;
    align-items: baseline;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
@media(max-width: 820px){
    .topSection{
        flex-wrap: wrap;
    }
}
.fundraisersHeader{
    text-align: left;
}
.bottomSection{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}
@media(max-width: 1400px){
    .bottomSection{
        justify-content: center;
    }
}
.fundraiser{
    margin-bottom: 35px;
}
.fundraiser:not(:last-of-type){
    margin-right: 30px;
}
@media(max-width: 1400px){
    .fundraiser{
        width: calc(50% - 66px) !important;
    }
    .fundraiser:nth-of-type(even){
        margin-right: 0;
    }
}
@media(max-width: 1320px){
    .fundraiser{
        width: calc(33% - 66px) !important;
    }
    .fundraiser:nth-of-type(even){
        margin-right: 30px;
    }
    .fundraiser:nth-of-type(3n){
        margin-right: 0;
    }
}
@media(max-width: 1200px){
    .fundraiser{
        width: calc(50% - 66px) !important;
    }
    .fundraiser:nth-of-type(even){
        margin-right: 0;
    }
    .fundraiser:nth-of-type(3n){
        margin-right: auto;
    }
}
@media(max-width: 820px){
    .fundraiser{
        width: calc(100% - 48px) !important;
        margin-bottom: 25px;
        margin-right: 0 !important;
    }
}
.rightSide{
    display: flex;
    position: relative;
    top: -8px;
    margin-left: auto;
}
@media(max-width: 600px){
    .rightSide{
        margin-top: 10px;
    }
}
.searchInput{
    margin-right: 20px;
}
@media(max-width: 820px){
    .rightSide{
        width: 100%;
    }
    .searchInput{
        width: calc(100% - 150px);
    }
    .searchInput > div{
        width: 100%;
    }
    .searchInput input{
        width: calc(100% - 80px);
    }
}
@media(max-width: 460px){
    .searchInput input{
        width: calc(100% - 75px);
    }
}
.searchOrder{
    margin-left: auto;
}
.noFundraisersFound, .noFundraisers{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
}
.noFundraisersFound{
    margin-bottom: 40px;
}
.magnifyingGlassWithQuestionMark, .coinJar{
    height: 60px;
    width: 60px;
}
.noFundraisersDescription{
    margin-top: 35px;
    color: #1F363D;
    text-align: center;
    font-size: 31px;
    font-family: 'Gotham Bold', serif;
}
.noFundraisersFooter{
    margin-top: 15px;
    font-size: 16px;
}
.noFundraisersFooterBold{
    display: inline-block;
    margin-left: 4px;
    color: #40798CCC;
    font-size: 16px;
    font-family: 'Gotham Bold', serif;
}
.noFundraisersButton{
    width: 150px;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 4px 20px;
    color: #FFFFFF;
    background-color: #40798C;
    font-size: 14px;
}
.impact{
    width: 383px;
    margin-top: 28px;
    padding-left: 35px;
    padding-right: 35px;
}
.impactHeader{
    margin-bottom: 30px;
    font-size: 32px;
    font-family: 'Gotham Bold', serif;
}
@media(max-width: 600px){
    .impact{
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
    .impactHeader{
        font-size: 24px;
    }
}
.totalDonationsAmount, .totalNumOfDonations{
    display: flex;
    margin-bottom: 45px;
    padding: 36px 25px;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.totalDonationsAmount{
    background-color: #40798C1A;
    border: 1px solid #40798C1A;
}
.totalNumOfDonations{
    background-color: #70A9A11A;
    border: 1px solid #70A9A11A
}
@media(max-width: 1320px){
    .impact{
        width: calc(100% - 70px);
        margin-top: 10px;
    }
    .impactStats{
        display: flex;
    }
    .totalDonationsAmount, .totalNumOfDonations{
        width: 50%;
        padding: 24px;
        margin-bottom: 30px;
    }
    .totalDonationsAmount{
        margin-right: 30px;
    }
}
@media(max-width: 820px){
    .impactStats{
        flex-direction: column;
    }
    .totalDonationsAmount, .totalNumOfDonations{
        width: calc(100% - 40px);
        margin-bottom: 15px;
        margin-right: 0;
    }
}
@media(max-width: 600px){
    .impact{
        width: calc(100% - 40px);
    }
    .totalDonationsAmount, .totalNumOfDonations{
        width: calc(100% - 32px);
        padding: 16px;
    }
}
.totalDonationsAmountDisplay, .totalNumOfDonationsDisplay{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 113px;
    width: 113px;
    border-radius: 500px;
}
@media(max-width: 1320px){
    .totalDonationsAmountDisplay, .totalNumOfDonationsDisplay{
        height: 72px;
        width: 72px;
    }
}
@media(max-width: 400px){
    .totalDonationsAmountDisplay, .totalNumOfDonationsDisplay{
        height: 54px;
        width: 54px;
    }
}
.totalDonationsAmountDisplay{
    background-color: #40798CCC;
}
.totalNumOfDonationsDisplay{
    background-color: #70A9A1CC;
}
.impactImage{
    height: 36px;
    width: 36px;
}
.impactDetails{
    width: calc(100% - 138px);
    margin-left: 25px;
}
.impactLabel{
    display: block;
    color: #1F363D;
    font-size: 18px;
    font-family: 'Gotham Medium', serif;
}
.impactValue{
    display: block;
    color: #1F363D;
    font-size: 72px;
    font-family: 'Gotham Bold', serif;
}
@media(max-width: 1320px){
    .impactLabel{
        font-size: 16px;
    }
    .impactValue{
        font-size: 40px;
    }
}
@media(max-width: 400px){
    .impactLabel{
        font-size: 14px;
    }
    .impactValue{
        font-size: 28px;
    }
}