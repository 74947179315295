.dualInputsRow{
    display: flex;
    margin-bottom: 15px;
}
.name input{
    width: 215px;
}
.singleLineInput{
    display: block !important;
    margin-bottom: 15px;
}
.singleLineInput input{
    width: calc(100% - 50px);
}
@media(max-width: 930px){
    .dualInputsRow{
        display: block;
        margin-bottom: 0;
    }
    .name{
        display: block;
        margin-bottom: 15px;
    }
    .name input{
        width: calc(100% - 50px);
    }
}
.button{
    width: 261px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
}