.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 400;
}
.container{
    position: relative;
    height: 90%;
    max-height: 650px;
    width: 85%;
    max-width: 760px;
    padding: 20px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.header{
    margin-bottom: 8px;
    color: #1F363D;
    font-size: 31px;
    font-family: 'Gotham Bold', serif;
}
.close{
    position: absolute;
    top: 28px;
    right: 33px;
    height: 12px;
    width: 12px;
    cursor: pointer;
}
.form{
    height: calc(100% - 50px);
}
.formInputs{
    height: calc(100% - 80px);
    overflow-y: auto;
    overflow-x: hidden;
}
.input{
    display: block !important;
    margin-top: 20px;
}
.input input{
    width: calc(100% - 44px);
}
.textEditor{
    display: block !important;
    height: 252px;
    margin-top: 20px;
    margin-bottom: 40px;
}
.textEditor textarea{
    width: calc(100% - 48px);
}
.buttons{
    display: flex;
    margin-top: 35px;
}
.buttonsApiError{
    margin-top: 9px;
}
.button{
    width: calc(50% - 8px);
    padding-left: 50px;
    padding-right: 50px;
}