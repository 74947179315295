.container{
    min-height: calc(100vh - 250px);
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    border-radius: 30px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.fundraiserFound{
    display: flex;
}
.leftSide{
    width: calc(100% - 523px);
    padding-left: 35px;
    padding-right: 35px;
}
@media(max-width: 1080px){
    .container{
        min-height: calc(100vh - 235px);
        padding-bottom: 25px;
    }
    .fundraiserFound{
        flex-direction: column;
    }
    .leftSide{
        width: calc(100% - 70px);
    }
}
@media(max-width: 600px){
    .container{
        min-height: calc(100vh - 215px);
    }
    .leftSide{
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
}
.header{
    text-align: left;
    word-break: normal;
}
.carouselContainer{
    display: flex;
    justify-content: center;
}
@media(max-width: 1080px){
    .carouselContainer{
        border-bottom: 1px solid #1F363D1A
    }
}
.carouselContainer :global(.carousel-root){
    width: 100%;
}
.carousel :global(.slider){
    cursor: pointer;
}
.carousel :global(.slide img){
    width: 100%;
    max-width: 800px;
    aspect-ratio: 16 / 9;
    border-radius: 30px;
    object-fit: cover;
}
@media(max-width: 1080px){
    .carousel :global(.thumbs-wrapper){
        margin-bottom: 5px;
    }
}
.carousel :global(.thumbs){
    display: flex;
    justify-content: center;
    margin-top: 0;
    padding-left: 0;
}
.carousel :global(.thumb){
    height: 48px;
    width: 85px !important;
    margin-right: 10px;
    padding: 0;
    border: none !important;
    cursor: pointer;
}
.carousel :global(.thumb img){
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    opacity: 0.5;
}
.carousel :global(.thumb.selected img){
    opacity: 1;
}
.description{
    padding-top: 30px;
    padding-bottom: 30px;
    color: #1F363D;
    white-space: pre-wrap;
}
.desktopComments, .desktopUpdates{
    border-top: 1px solid #1F363D1A;
}
@media(max-width: 1080px){
    .desktopDescription, .desktopBottomViewHeaders, .desktopComments, .desktopUpdates{
        display: none;
    }
}
.rightSide{
    width: 383px;
    margin-top: 38px;
    padding-left: 35px;
    padding-right: 35px;
}
@media(max-width: 1080px){
    .rightSide{
        width: calc(100% - 70px);
        margin-top: 30px;
    }
}
@media(max-width: 600px){
    .rightSide{
        width: calc(100% - 40px);
        margin-top: 25px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.countdownClock{
    margin-bottom: 15px;
}
@media(max-width: 1080px){
    .countdownClock{
        margin-bottom: 30px;
    }
}
@media(max-width: 600px){
    .countdownClock{
        margin-bottom: 25px;
    }
}
.mobileDescription{
    display: none;
    width: calc(100% - 70px);
    margin-left: 35px;
    margin-right: 35px;
}
.mobileBottomViewHeaders, .mobileComments, .mobileUpdates{
    display: none;
    width: calc(100% - 70px);
    padding-left: 35px;
    padding-right: 35px;
}
@media(max-width: 1080px){
    .mobileDescription, .mobileBottomViewHeaders, .mobileComments, .mobileUpdates{
        display: block;
    }
}
@media(max-width: 600px){
    .mobileDescription, .mobileBottomViewHeaders, .mobileComments, .mobileUpdates{
        width: calc(100% - 40px);
    }
    .mobileDescription{
        margin-left: 20px;
        margin-right: 20px;
    }
    .mobileBottomViewHeaders, .mobileComments, .mobileUpdates{
        padding-left: 20px;
        padding-right: 20px;
    }
}
.fundraiserNotFound{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    text-align: center;
}
@media(max-width: 600px){
    .fundraiserNotFound{
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 40px);
        text-align: center;
    }
}
.brokenHeart{
    height: 60px;
    width: 60px;
    margin-top: calc(40% - 50px);
    margin-bottom: 30px;
}
.fundraiserNotFoundDescription{
    color: #1F363D;
    text-align: center;
    font-size: 31px;
    font-family: 'Gotham Bold', serif;
    line-height: 37px;
}