.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 400;
}
.container{
    position: relative;
    height: 90%;
    width: 85%;
    max-width: 412px;
    padding: 20px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.donated{
    height: auto !important;
}
.header{
    margin-bottom: 8px;
    color: #1F363D;
    font-size: 31px;
    font-family: 'Gotham Bold', serif;
}
.close{
    position: absolute;
    top: 28px;
    right: 33px;
    height: 12px;
    width: 12px;
    cursor: pointer;
}
.form{
    height: calc(100% - 50px);
}
.formInputs{
    height: calc(100% - 80px);
    padding-left: 4px;
    padding-right: 4px;
    overflow-y: auto;
    overflow-x: hidden;
}
.amountInput{
    display: block !important;
    margin-top: 25px;
    margin-bottom: 15px;
}
.amountInput input{
    width: calc(100% - 86px);
    font-family: 'Gotham Medium', serif;
    font-size: 22px;
    border: 6px solid #40798C66;
}
.amountInput input:focus{
    border: 6px solid #40798C66;
}
.input{
    display: block !important;
    margin-top: 20px;
}
.input input{
    width: calc(100% - 44px);
}
.textEditor{
    display: block !important;
    height: 126px;
    margin-top: 20px;
}
.textEditor textarea{
    width: calc(100% - 48px);
}
.buttons{
    display: flex;
    margin-top: 35px;
}
.buttonsApiError{
    margin-top: 9px;
}
.button{
    width: 194px;
    padding-left: 50px;
    padding-right: 50px;
}
@media(max-width: 500px){
    .button{
        width: calc(50% - 6px);
    }
}
.coinJar{
    display: block;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    width: 40px;
    margin-top: 25px;
}
.donatedHeader{
    margin-top: 20px;
    color: #40798C;
    text-align: center;
    font-family: 'Gotham Bold', serif;
    font-size: 16px;
}
.donatedText{
    margin-top: 15px;
    color: #1F363D;
    text-align: center;
    font-size: 14px;
}
.donatedAmount, .donatedOrganization{
    font-family: 'Gotham Bold', serif;
}
.donatedButton{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 194px;
    margin-top: 25px;
    padding-left: 50px;
    padding-right: 50px;
}