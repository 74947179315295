.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 400;
}
.container{
    position: relative;
    width: 85%;
    max-width: 412px;
    padding: 20px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.header{
    margin-bottom: 8px;
    color: #1F363D;
    font-size: 31px;
    font-family: 'Gotham Bold', serif;
}
.close{
    position: absolute;
    top: 28px;
    right: 33px;
    height: 12px;
    width: 12px;
    cursor: pointer;
}
.buttons{
    display: flex;
    flex-wrap: wrap;
}
.button{
    justify-content: left;
    width: 194px;
    margin-top: 15px;
}
@media(max-width: 500px){
    .button{
        width: 100%;
        margin-right: 0 !important;
    }
}
.copyButton > img{
    height: 10px;
    width: 20px;
    margin-right: 10px;
}
.copiedButton{
    background-color: #F0F6F5;
    border-color: #70A9A11A;
}
.copiedButton > img{
    height: 12px;
    width: 16px;
    margin-right: 14px;
}
.emailButton > img{
    height: 20px;
    width: 22px;
    margin-right: 10px;
}
.xButton > img{
    height: 17px;
    width: 19px;
    margin-right: 12px;
}
.facebookButton > img{
    height: 21px;
    width: 11px;
    margin-right: 21px;
}
.button:nth-of-type(odd){
    margin-right: 15px;
}