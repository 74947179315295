.container{
    display: flex;
    align-items: center;
    height: 57px;
    padding: 0 15px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
    cursor: pointer;
}
.selected{
    outline: 5px solid #BAD8D4;
}
.outerCircle{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    margin-right: 10px;
    border: 1px solid #1F363D33;
    border-radius: 50%;
}
.innerCircle{
    display: none;
    height: 12px;
    width: 12px;
    background-color: #40798C;
    border-radius: 50%;
}
.selected .innerCircle{
    display: inline-block;
}
.text{
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
}
.selected .text{
    color: #40798C;
}