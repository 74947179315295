.container{
    width: calc(100% - 50px);
    padding: 24px 25px;
    border: 1px solid #1F363D1A;
    border-radius: 25px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.header{
    margin-bottom: 15px;
    color: #40798C;
    font-family: 'Gotham Bold', serif;
}
.endAt{
    display: flex;
    justify-content: center;
    padding: 12px 25px;
    color: #40798C;
    background-color: #70A9A11A;
    border: 1px solid #70A9A11A;
    border-radius: 25px;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
}
@media(max-width: 1080px){
    .container{
        display: flex;
        align-items: center;
        padding: 24px 24px;
    }
    .header{
        margin-bottom: 0;
    }
    .remainingTime, .endAt{
        margin-left: auto;
    }
}
@media(max-width: 600px){
    .container{
        display: block;
        padding: 24px 25px;
    }
    .header{
        margin-bottom: 15px;
    }
    .remainingTime, .endAt{
        margin-left: 0;
    }
}
.days, .hours, .minutes, .seconds{
    display: inline-block;
    padding: 14px 20px;
    color: #40798C;
    background-color: #70A9A11A;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
    border: 1px solid #70A9A11A;
    border-radius: 25px;
}
@media(max-width: 480px){
    .days, .hours, .minutes, .seconds{
        padding: 14px 12px;
    }
}
.divider{
    margin-left: 5px;
    margin-right: 5px;
    color: #40798C;
    font-family: 'Gotham Bold', serif;
}