.container{
    display: inline-block;
}
.label{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.asterisk{
    margin-left: 5px;
    color: #EB443F;
}
.inputContainer{
    position: relative;
}
.input{
    height: 16px;
    padding: 20px 24px 20px 24px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
}
.input::placeholder{
    color: #1F363D80;
}
.password::placeholder{
    position: relative;
    top: 3.5px;
}
.input:focus{
    border: 1px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.error{
    border: 1px solid #EB443F;
}
.disabled{
    color: #1F363D80;
}
.togglePassword{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}