.container{
    padding-bottom: 15px;
}
.header{
    padding-bottom: 12.5px;
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
    cursor: pointer;
}
.header:not(:last-child){
    margin-right: 30px;
}
.selected{
    color: #274C77;
    border-bottom: 3px solid #274C77;
}