.donation{
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
}
.donation:not(:last-of-type){
    border-bottom: 1px solid #1F363D1A;
}
.coin{
    position: relative;
    top: 2.5px;
    height: 12.5px;
    width: 12.5px;
    margin-right: 10px;
}
.sentence{
    color: #1F363D;
    font-size: 14px;
}
.name{
    font-family: 'Gotham Medium', serif;
}
.datetime{
    margin-top: 5px;
    color: #1F363D;
    font-size: 12px;
    opacity: 60%;
}