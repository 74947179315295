.loginButton{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    padding: 0 26px;
    color: #40798C;
    background-color: #FFFFFF;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
    border: 1px solid #40798C66;
    border-radius: 500px;
    cursor: pointer;
}
@media(max-width: 480px){
    .loginButton{
        width: 160px;
    }
}
@media(max-width: 420px){
    .loginButton{
        width: 130px;
    }
}
.person{
    height: 16px;
    width: 16px;
    margin-right: 8px;
}