.singleLineInput{
    display: block !important;
    margin-bottom: 15px;
}
.singleLineInput input{
    width: calc(100% - 50px);
}
.button{
    width: 261px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
}