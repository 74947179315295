.container{
    display: block;
}
.label{
    display: inline-block;
    position: relative;
    top: -9.5px;
    color: #1F363D;
    margin-left: 7px;
    font-size: 14px;
    line-height: 14px;
}
.checkbox[type="checkbox"]{
    height: 24px;
    width: 24px;
    border: 1px solid #1F363D33;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.checkbox[type="checkbox"]:checked{
    color: #FFFFFF;
    background-color: #40798C;
    background-size: 60% 60%;
    background-repeat: no-repeat;
    background-position: center;
}