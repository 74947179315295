.button{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 25px;
    color: #40798C;
    background-color: #FFFFFF;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
    border: 1px solid #40798C66;
    border-radius: 500px;
    outline: none;
    cursor: pointer;
}
.dark{
    color: #FFFFFF;
    background-color: #40798C;
}
.disabled{
    background-color: #1F363D1A;
    border: none;
}
.image{
    margin-right: 8px;
    height: 20px;
    width: 20px;
}