.container{
    top: 0;
    width: calc(100% - 70px);
    padding-left: 35px;
    padding-right: 35px;
    background-color: #FFFFFF;
    border: 1px solid #70A9A11A;
    border-radius: 0 0 30px 30px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.nav{
    display: flex;
    align-items: center;
    height: 93px;
}
@media(max-width: 600px){
    .container{
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
    .nav{
        height: 73px;
    }
}
.title{
    color: #274C77;
    font-family: 'Gotham Bold', serif;
    font-size: 24px;
    text-decoration: none;
}
.title img{
    position: relative;
    top: 3px;
    height: 30px;
    width: 150px;
}
.menu, .close{
    display: none;
    cursor: pointer;
}
.menu{
    height: 12px;
    width: 18px;
}
.close{
    height: 14px;
    width: 14px;
}
.links{
    margin-left: 60px;
}
@media(max-width: 1020px){
    .links{
        margin-left: 35px;
    }
}
@media(max-width: 900px){
    .title{
        display: none;
    }
    .menu, .close{
        display: inline-block;
    }
    .links{
        display: none;
    }
}
.link{
    margin-right: 50px;
    color: #1F363D;
    text-decoration: none;
    font-family: 'Gotham Medium', serif;
    font-size: 16px;
}
@media(max-width: 1020px){
    .link{
        margin-right: 30px;
    }
}
.link.active{
    padding-bottom: 32.5px;
    border-bottom: 5px solid #274C77;
}
.profileContainer{
    display: flex;
    position: relative;
    margin-left: auto;
}
.notificationsToggle{
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 25px;
    cursor: pointer;
}
.bell{
    height: 24px;
    width: 24px;
}
.unseenNotificationsCount{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: -5px;
    height: 17px;
    width: 17px;
    color: #FFFFFF;
    background: #F05252;
    font-size: 12px;
    border-radius: 50%;
}
.extendedCount{
    height: 20px;
    width: 20px;
    font-size: 8px;
}
.profile{
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-left: 1px solid #1F363D1A;
    cursor: pointer;
}
.profilePicture{
    height: 51px;
    width: 51px;
    border-radius: 50%;
    object-fit: cover;
}
.profilePicturePlaceholder{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51px;
    width: 51px;
    color: #578E86;
    background-color: #BFE1DC;
    font-size: 12px;
    font-family: 'Gotham Medium', serif;
    border-radius: 50%;
    letter-spacing: -1.5px;
}
.name{
    max-width: 180px;
    margin-left: 10px;
    color: #1F363D;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
}
@media(max-width: 950px){
    .name{
        max-width: 140px;
    }
}
@media(max-width: 900px){
    .name{
        max-width: 180px;
    }
}
@media(max-width: 500px){
    .name{
        display: none;
    }
}
.downCaret{
    margin-left: 10px;
}
.subNav{
    position: absolute;
    top: 60px;
    left: 70px;
    width: calc(100% - 90px);
    min-width: 145px;
    padding: 5px 18px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 10px;
    box-shadow: 0 3px 12px 0 #00000012;
    z-index: 300;
}
@media(max-width: 500px){
    .subNav{
        left: auto;
        right: -20px;
        width: 145px;
    }
}
.subNavIcon{
    position: relative;
    top: 1px;
    height: 12px;
    width: 12px;
    margin-right: 12px;
}
.subNavLink{
    display: block;
    width: 100%;
    padding: 15px 0;
    color: #1F363D;
    background-color: #FFFFFF;
    text-align: left;
    font-size: 14px;
    font-family: 'Gotham', serif;
    text-decoration: none;
    border: none;
    cursor: pointer;
}
.subNavLink:not(:last-child){
    border-bottom: 1px solid #1F363D1A;
}
.mobileLinks{
    margin-bottom: 30px;
    border-top: 1px solid #1F363D1A;
}
.mobileLinks .link{
    display: block;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.mobileLinks .link.active{
    padding-left: 15px;
    margin-left: -20px;
    border-left: 5px solid #274C77;
    border-bottom: none;
}