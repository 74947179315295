.container{
    top: 0;
    width: calc(100% - 70px);
    padding-left: 35px;
    padding-right: 35px;
    background-color: #FFFFFF;
    border: 1px solid #70A9A11A;
    border-radius: 0 0 30px 30px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.nav{
    display: flex;
    align-items: center;
    height: 93px;
}
@media(max-width: 600px){
    .container{
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
    .nav{
        height: 73px;
    }
}
.title{
    color: #274C77;
    font-family: 'Gotham Bold', serif;
    font-size: 24px;
    text-decoration: none;
}
.title img{
    position: relative;
    top: 3px;
    height: 30px;
    width: 150px;
}
.profileContainer{
    display: flex;
    position: relative;
    margin-left: auto;
}