@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Gotham-Book.otf');
}
@font-face {
    font-family: 'Gotham Medium';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Gotham-Medium.otf');
}
@font-face {
    font-family: 'Gotham Bold';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/Gotham-Bold.otf');
}
html, body{
    height: auto;
}
body{
    margin: 0 30px;
    background-color: #F1F7F6;
    font-family: 'Gotham', serif;
    font-weight: 400;
}
@media(max-width: 600px){
    body{
        margin: 0 10px;
    }
}
#root{
    height: 100%;
}