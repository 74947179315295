.label{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.urlRow{
    display: flex;
    margin-left: 10px;
}
.pencil, .cancel{
    position: relative;
    top: 1px;
    height: 15px;
    width: 15px;
    margin-right: 10px;
    cursor: pointer;
}
.cancel{
    position: relative;
    top: 4px;
    height: 12px;
    width: 12px;
    margin-right: 10px;
    cursor: pointer;
}
.url{
    position: relative;
    margin-right: 15px;
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.slug{
    width: 150px;
    color: #1F363D;
    font-size: 16px;
    font-family: 'Gotham Medium', serif;
    border-color: #1F363D;
    border-width: 0 0 2px 0;
    outline: none;
}
@media(max-width: 900px){
    .urlPrefixFixedWidth{
        display: inline-block;
        max-width: calc(100% - 160px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .slugContainer{
        position: relative;
        top: -4px;
    }
    .slug{
        width: 140px;
    }
}
@media(min-width: 630px) and (max-width: 700px){
    .slug{
        width: 150px;
    }
}
.copy, .copyStatus, .floppyDisk{
    margin-left: auto;
    cursor: pointer;
}
.copy{
    height: 20px;
    width: 17px;
}
.copyStatus, .saveStatus{
    display: flex;
    position: relative;
    top: 4px;
}
.copyStatusIcon{
    height: 12px;
    width: 16px;
}
.copyStatusText, .saveStatusText{
    margin-left: 5px;
    color: #40798C;
    font-size: 12px;
    font-family: 'Gotham Medium', serif;
}
.floppyDisk{
    position: relative;
    top: 2px;
    height: 15px;
    width: 15px;
}
.saveStatus{
    margin-left: auto;
}
.saveStatusIcon{
    margin-left: auto;
    height: 12px;
    width: 16px;
}