.notifications{
    position: absolute;
    top: 60px;
    left: 0;
    max-height: 550px;
    width: 100%;
    padding: 5px 18px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 10px;
    box-shadow: 0 3px 12px 0 #00000012;
    overflow-y: auto;
}
@media(max-width: 600px){
    .notifications{
        width: calc(100% - 20px);
    }
}
@media(max-width: 500px){
    .notifications{
        left: -140px;
        width: calc(100% + 120px);
    }
}
.notification{
    display: flex;
    position: relative;
    padding: 15px 0;
    color: #1F363D;
    font-size: 14px;
    text-decoration: none;
}
.notification:not(:first-child){
    border-top: 1px solid #1F363D1A;
}
.dollarCircleOrange{
    position: relative;
    top: 3px;
    height: 12.5px;
    width: 12.5px;
}
.middleSection{
    width: calc(100% - 70px);
    margin-left: 20px;
}
.datetime{
    margin-top: 5px;
    color: #1F363D;
    font-size: 12px;
    opacity: 60%;
}
.eyeCircleRed{
    position: relative;
    height: 16px;
    width: 16px;
    margin-left: auto;
}
.noNotifications{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 20px 0;
    color: #1F363D;
    font-size: 12px;
}