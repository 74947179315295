.header{
    color: #1F363D;
    text-align: center;
    font-family: 'Gotham Bold', serif;
    font-size: 42px;
    line-height: 50.4px;
    word-break: break-all;
}
@media(max-width: 600px){
    .header{
        font-size: 28px;
        line-height: 34px;
    }
}